var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "add-job-desc-dialog",
      attrs: {
        title: "提示",
        visible: _vm.visible,
        width: "498px",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("p", [_vm._v("如有需要，可以给录制的职播加一段文字描述")]),
      _c("el-input", {
        attrs: {
          type: "textarea",
          rows: 4,
          placeholder: "如有需要，可以给录制的职播加一段文字描述",
          maxlength: "500",
          "show-word-limit": "",
        },
        model: {
          value: _vm.textarea,
          callback: function ($$v) {
            _vm.textarea = $$v
          },
          expression: "textarea",
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleSubmit(0)
                },
              },
            },
            [_vm._v("不填写")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit(1)
                },
              },
            },
            [_vm._v("提 交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }