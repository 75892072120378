var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "video-record-dialog",
      attrs: {
        title: "职播-录制视频",
        visible: _vm.visible,
        width: "1000px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "video-box col-div",
          staticStyle: { "justify-content": "flex-end" },
          attrs: { id: "main-video" },
        },
        [
          !_vm.roomJoined && !_vm.tipMsgShow
            ? _c("p", { staticClass: "before-video-load" }, [
                _vm._v("摄像设备启动中..."),
              ])
            : _vm._e(),
          _vm.tipMsgShow
            ? _c("div", { staticClass: "tip-box" }, [
                _c("p", [_vm._v(_vm._s(_vm.tipMsg))]),
              ])
            : _vm._e(),
          _c("span", {
            staticClass: "close",
            class: _vm.tipMsgShow ? "close-white" : "",
            on: {
              click: function ($event) {
                return _vm.hideDialog()
              },
            },
          }),
          _vm.roomJoined && !_vm.tipMsgShow && _vm.recordTimes
            ? _c("div", { staticClass: "time-box" }, [
                _c("span"),
                _vm._v(
                  "\n            " + _vm._s(_vm.recordTimes) + "\n        "
                ),
              ])
            : _vm._e(),
          _vm.roomJoined && !_vm.tipMsgShow
            ? _c(
                "div",
                { staticClass: "operate-btns" },
                [
                  !_vm.recording && !_vm.recordComplete
                    ? _c("span", {
                        staticClass: "start-record",
                        on: { click: _vm.startRecord },
                      })
                    : _vm._e(),
                  _vm.recording
                    ? _c("span", {
                        staticClass: "stop-record",
                        on: { click: _vm.stopRecord },
                      })
                    : _vm._e(),
                  _vm.recordComplete
                    ? _c(
                        "el-button",
                        {
                          staticClass: "upload-record",
                          attrs: { type: "primary" },
                          on: { click: _vm.uploadRecord },
                        },
                        [_vm._v("上传")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "提示",
            visible: _vm.innerVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c("p", [_vm._v("你录制的职播还没有上传，确定关闭？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelInner } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmInner },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }