<template>
    <el-dialog
        class="audio-record-dialog"
        title="职播-录音"
        :visible.sync="visible"
        width="480px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="beforeHideDialog"
    >
        <div v-if="tipMsgShow" class="tip-msg">{{tipMsg}}</div>
        <div v-if="!tipMsgShow" class="audio-box col-div" style="justify-content: flex-end">
            <p v-if="!roomJoined" class="before-record">录音设备启动中...</p>
            <div v-if="!recording && !recordComplete && roomJoined" class="start-record">
                <p>点击录音</p>
                <span @click="startRecord"></span>
            </div>
            <div v-if="recording" class="recording">
                <p>
                    <span class="left">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                    </span>
                    <span class="recording-time">{{recordTimes}}</span>
                    <span class="right">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                    </span>
                </p>
                <span @click="stopRecord"></span>
            </div>
            <div v-if="recordComplete" class="upload-btns">
                <p>录制完成！</p>
                <el-button @click="cancelUpload">取消</el-button>
                <el-button type="primary" @click="uploadRecord">上 传</el-button>
            </div>
        </div>
        <el-dialog
            width="30%"
            title="提示"
            :visible.sync="innerVisible"
            append-to-body
        >
            <p>你录制的职播还没有上传，确定关闭？</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancelInner">取消</el-button>
                <el-button type="primary" @click="handleConfirmInner">确定</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
import RtcClient from './trtc-client.js';
import mediaService from '#/js/service/mediaService.js';
import { getAppId } from './trtc-config.js';

export default {
    name: 'videoRecordDialog',
    data() {
        return {
            rtcClient: null,
            visible: false,
            innerVisible: false,
            loading: false,
            recordTimeStamp: 0,
            recordTimeInterval: null,
            recording: false,
            recordComplete: false,
            tipMsgShow: false,
            tipMsg: '未检测到录音设备',
            roomJoined: false
        }
    },
    props: {
        uuid: String,
        roomId: Number
    },
    computed: {
        unionId() {
            return this.$store.state.user.userInfo.unionId;
        },
        recordTimes() {
            if(this.recordTimeStamp == 0) {
                return '00:00';
            }
            let timeStamp = this.recordTimeStamp;
            let seconds = Math.floor(timeStamp / 1000);
            let minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
            if(minutes < 10) {
                minutes = '0' + minutes;
            }
            if(seconds < 10) {
                seconds = '0' + seconds;
            }
            return minutes + ':' + seconds;
        }
    },
    mounted() {
    },
    methods: {
        show() {
            this.visible = true;
            this.loading = true;
            mediaService.getUserSig()
                .then(res =>{
                    console.log(this.uuid);
                    this.loading = false;
                    
                    const sdkAppId = getAppId();
                    const userSig = res;

                    this.rtcClient = new RtcClient({
                        sdkAppId: sdkAppId,
                        userSig: userSig,
                        userId: this.unionId,
                        userDefineRecordId: this.uuid,
                        roomId: this.roomId
                    });

                    this.rtcClient.join(0)
                        .then(() =>{
                            this.roomJoined = true;
                        }).catch(err => {
                            if(err.message.indexOf('Requested device not found') > -1 || err.name == 'NotFoundError') {
                                if(/macintosh|mac os x/i.test(navigator.userAgent)) {
                                    this.tipMsg = '应用未被授予麦克风的访问权限，请在系统偏好设置中授权访问权限';
                                }else {
                                    this.tipMsg = '请先打开麦克风';
                                }
                                this.tipMsgShow = true;
                            }else {
                                this.tipMsg = err.message || '录制遇到了问题';
                                this.tipMsgShow = true;
                            }
                        });
                }).catch(err =>{
                    this.loading = false;
                    shortTips(err.msg || '获取签名失败');
                    this.visible = false;
                })
        },
        beforeHideDialog() {
            this.hideDialog(false);
        },
        hideDialog(donotCheck) {
            if(!donotCheck && (this.recording || this.recordComplete)) {
                this.innerVisible = true;
                return false;
            }

            this.roomJoined = false;
            this.visible = false;
            this.stopRecord();
            this.rtcClient && this.rtcClient.reset();
            this.recordComplete = false;
        },
        startRecord() {
            this.recording = true;
            this.$emit('start-record');
            this.recordTimeInterval = setInterval(() => {
                this.recordTimeStamp += 1000;
            }, 1000);
            if(this.rtcClient) {
                this.rtcClient.publish()
                    .then()
                    .catch(err =>{
                        this.tipMsg = err.message || '录制遇到了问题';
                        this.tipMsgShow = true;
                    }) 
            }
        },
        stopRecord() {
            if(this.recording && this.recordTimeStamp < 10000) {
                shortTips('录制时长需在10秒以上，请稍后重试');
                return false;
            }
            this.recording = false;
            this.recordComplete = true;
            clearInterval(this.recordTimeInterval);
            this.recordTimeInterval = null;
            this.recordTimeStamp = 0;
            this.rtcClient && this.rtcClient.unpublish();
        },
        cancelUpload() {
            this.hideDialog(true);
        },
        uploadRecord() {
            this.$emit('upload', {type: 0, recordKey: this.uuid});
            this.hideDialog(true);
        },
        handleCancelInner() {
            this.innerVisible = false;
        },
        handleConfirmInner() {
            this.innerVisible = false;
            this.hideDialog(true);
        },
    }
}
</script>

<style lang="scss" scoped>
    .audio-record-dialog {
        text-align: center;

        /deep/ .el-dialog__header {
            text-align: left;
        }

        .audio-box {
            height: 260px;
            padding-top: 18px;

            .before-record {
                padding-top: 50px;
                font-size: 16px;
                text-align: center;
            }
        }

        .start-record {
            padding-bottom: 57px;
            p {
                padding-top: 15px;
                margin-bottom: 27px;
                font-size: 17px;
                color: #aaa;
            }

            span {
                display: inline-block;
                position: relative;
                width: 110px;
                height: 110px;
                background: #FFFFFF;
                box-shadow: 0 0 8px 0 #DDDDDD;
                border-radius: 50%;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    width: 80px;
                    height: 80px;
                    background: rgba(56,188,157,0.80);
                    border-radius: 50%;
                }
            }
        }

        .recording {
            padding-bottom: 57px;
            p {
                font-size: 16px;
                color: #aaa;
                height: 16px;
                line-height: 16px;
                margin-bottom: 32px;
                span {
                    display: inline-block;
                }
                .recording-time {
                    margin: 0 10px;
                    vertical-align: top;
                }

                .left i {
                    float: right;
                    width: 4px;
                    height: 16px;
                    background-color: #ddd;
                    margin-left: 5px;

                    &:first-child {
                        background-color: #aaa;;
                    }
                }

                .right i {
                    float: left;
                    width: 4px;
                    height: 16px;
                    background-color: #ddd;
                    margin-right: 5px;

                    &:first-child {
                        background-color: #aaa;;
                    }
                }
            }

            > span {
                display: inline-block;
                position: relative;
                width: 110px;
                height: 110px;
                background: #FFFFFF;
                box-shadow: 0 0 8px 0 #DDDDDD;
                border-radius: 50%;
                cursor: pointer;

                &::after {
                    content: '';
                    position: absolute;
                    top: 36px;
                    left: 36px;
                    width: 38px;
                    height: 38px;
                    background: rgba(56,188,157,0.80);
                    border-radius: 2px;
                }
            }
        }

        .upload-btns {
            p {
                font-size: 16px;
                color: #333;
                margin-top: 60px;
                margin-bottom: 100px;
            }
        }
    }
    .video-record-dialog /deep/ {
        .el-dialog__header {
            display: none;
        }
        .el-dialog .el-dialog__body {
            padding: 0;
        }

    }
</style>>
