import { render, staticRenderFns } from "./browserNotSupportDialog.vue?vue&type=template&id=ccfe8952&scoped=true&"
import script from "./browserNotSupportDialog.vue?vue&type=script&lang=js&"
export * from "./browserNotSupportDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccfe8952",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ccfe8952')) {
      api.createRecord('ccfe8952', component.options)
    } else {
      api.reload('ccfe8952', component.options)
    }
    module.hot.accept("./browserNotSupportDialog.vue?vue&type=template&id=ccfe8952&scoped=true&", function () {
      api.rerender('ccfe8952', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/component/mediaRecord/browserNotSupportDialog.vue"
export default component.exports