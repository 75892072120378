<template>
    <el-dialog
        class="browser-not-support-dialog"
        title="提示"
        :visible.sync="visible"
        width="480px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <p>当前浏览器不支持职位讲解功能，请更换至新版chrome浏览器后使用</p>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleClose">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'browserNotSupportDialog',
    data() {
        return {
            visible: false
        }
    },
    mounted() {
    },
    methods: {
        show() {
            this.visible = true;
        },
        handleClose() {
            this.visible = false;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>>
