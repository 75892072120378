import TRTC from 'trtc-js-sdk';

function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod');
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }

    if(flag && userAgentInfo.indexOf('Mac OS X') > -1 && 'ontouchend' in document) {
        flag = false;
    }

    return flag;
}

export default class RtcClient {
    constructor(options) {
        this.sdkAppId_ = options.sdkAppId;
        this.userId_ = options.userId;
        this.userSig_ = options.userSig;
        this.roomId_ = options.roomId;
        this.userDefineRecordId_ = options.userDefineRecordId || '';

        this.isJoined_ = false;
        this.isPublished_ = false;
        this.isAudioMuted = false;
        this.isVideoMuted = false;
        this.localStream_ = null;
        this.remoteStreams_ = [];
        this.members_ = new Map();

        let params = {
            mode: 'rtc',
            sdkAppId: this.sdkAppId_,
            userId: this.userId_,
            userSig: this.userSig_
        };
        if(this.userDefineRecordId_) {
            params.userDefineRecordId = this.userDefineRecordId_;
        }
        this.client_ = TRTC.createClient(params);
    }

    async join(type) {
        await this.client_.join({
            roomId: this.roomId_,
        });
        this.isJoined_ = true;
        let params = {
            audio: true,
            video: type == 1 ? true : false,
            userId: this.userId_,
            mirror: true
        }
        console.log(params);
        this.localStream_ = TRTC.createStream(params);
        if(type == 1) {
            if(isPC()) {
                this.localStream_.setVideoProfile('1080p');
            }else {
                this.localStream_.setVideoProfile('720p');
            }
        }
        
        await this.localStream_.initialize();

        if(type == 1) {
            this.localStream_.play('main-video');
        }
    }

    async publish() {
        if (!this.isJoined_) {
            console.warn('publish() - please join() firstly');
            return;
        }
        if (this.isPublished_) {
            console.warn('duplicate RtcClient.publish() observed');
            return;
        }
        try {
            await this.client_.publish(this.localStream_);
        } catch (e) {
            this.isPublished_ = false;
            throw(e);
        }
        this.isPublished_ = true;
    }
    
    async unpublish() {
        if (!this.isJoined_) {
            console.warn('unpublish() - please join() firstly');
            return;
        }
        if (!this.isPublished_) {
            console.warn('RtcClient.unpublish() called but not published yet');
            return;
        }
    
        await this.client_.unpublish(this.localStream_);
        this.isPublished_ = false;

        await this.client_.leave();
        // this.localStream_.stop();
        // this.localStream_.close();
        // this.localStream_ = null;
        // this.isJoined_ = false;
    }

    reset() {
        this.localStream_ && this.localStream_.stop();
        this.localStream_ && this.localStream_.close();
        this.localStream_ = null;
        this.isJoined_ = false;
    }
}
