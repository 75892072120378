<template>
    <el-dialog
        class="add-job-desc-dialog"
        title="提示"
        v-loading="loading"
        :visible.sync="visible"
        width="498px"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <p>如有需要，可以给录制的职播加一段文字描述</p>
        <el-input
            type="textarea"
            :rows="4"
            placeholder="如有需要，可以给录制的职播加一段文字描述"
            v-model="textarea"
            maxlength="500"
            show-word-limit
        >
        </el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleSubmit(0)">不填写</el-button>
            <el-button type="primary" @click="handleSubmit(1)">提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
import mediaService from '#/js/service/mediaService.js'
export default {
    name: 'addJobDescDialog',
    data() {
        return {
            loading: false,
            visible: false,
            textarea: '',
            type: 1, //1:视频，0:音频
            jobIds: [],
            recordKey: '',
            roomId: '',
            recordStartTime: ''
        }
    },
    mounted() {
    },
    methods: {
        show(json) {
            this.textarea = '';
            this.type = json.type;
            this.jobIds = json.jobIds;
            this.recordKey = json.recordKey;
            this.roomId = json.roomId;
            this.recordStartTime = json.recordStartTime;
            this.visible = true;
        },
        handleClose() {
            this.visible = false;
        },
        handleSubmit(useDesc) {
            this.loading = true;
            let deviceType = navigator.userAgent;
            let isIpadOrIphone = false;
            if(deviceType.indexOf('iPad') > -1 || deviceType.indexOf('iPhone') > -1) {
                isIpadOrIphone = true;
            }
            if(deviceType.indexOf('Mac OS X') > -1 && 'ontouchend' in document && deviceType.indexOf('iPad') == -1) {
                deviceType = 'iPad ' + deviceType;

                if(deviceType.indexOf('iPhone') == -1) {
                    isIpadOrIphone = true;
                }
            }
            let rotateDeg = 0;
            if(isIpadOrIphone && 'orientation' in window) {
                let orientation = window.orientation || 0;
                if(orientation == 0) {
                    rotateDeg = 90;
                }else if(orientation == -90) {
                    rotateDeg = 0;
                }else if(orientation == 90) {
                    rotateDeg = 180;
                }else if(orientation == 180) {
                    rotateDeg = 270;
                }
            }

            let params = {
                jobIds: this.jobIds,
                sourceFrom: 'WEB',
                type: this.type,
                recordKey: this.recordKey,
                roomId: this.roomId,
                recordStartTime: this.recordStartTime,
                deviceType: deviceType,
                rotationAngle: rotateDeg
            }
            if(useDesc == 1) {
                params.description = this.textarea;
            }
            mediaService.getRecordId(params)
                .then(res => {
                    this.$emit('upload-success', this.type);
                    this.visible = false;
                }).catch(err =>{
                    shortTips(err.msg || err.errorMessage || '系统异常');
                }).finally(() => {
                    this.loading = false;
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>>
