export const getAppId = () => {
    let appId = '';
    if(location.host == 'portal.liebide.com') {
        appId = 1400356467;
    }else if (location.host == 'portal.stg.liebide.com') {
        appId = 1400383580;
    }else {
		appId = 1400377870
	}

	return appId;
}