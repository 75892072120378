<template>
    <el-dialog
        class="video-record-dialog"
        title="职播-录制视频"
        :visible.sync="visible"
        width="1000px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div id="main-video" class="video-box col-div" style="justify-content: flex-end">
            <p v-if="!roomJoined && !tipMsgShow" class="before-video-load">摄像设备启动中...</p>
            <div v-if="tipMsgShow" class="tip-box">
                <p>{{tipMsg}}</p>
            </div>
            <span class="close" :class="tipMsgShow ? 'close-white' : ''" @click="hideDialog()"></span>
            <div v-if="roomJoined && !tipMsgShow && recordTimes" class="time-box">
                <span></span>
                {{recordTimes}}
            </div>
            <div v-if="roomJoined && !tipMsgShow" class="operate-btns">
                <span v-if="!recording && !recordComplete" class="start-record" @click="startRecord"></span>
                <span v-if="recording" class="stop-record" @click="stopRecord"></span>
                <el-button v-if="recordComplete" class="upload-record" @click="uploadRecord" type="primary">上传</el-button>
            </div>
        </div>
        <el-dialog
            width="30%"
            title="提示"
            :visible.sync="innerVisible"
            append-to-body
        >
            <p>你录制的职播还没有上传，确定关闭？</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancelInner">取消</el-button>
                <el-button type="primary" @click="handleConfirmInner">确定</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
import RtcClient from './trtc-client.js';
import mediaService from '#/js/service/mediaService.js';
import { getAppId } from './trtc-config.js';

export default {
    name: 'videoRecordDialog',
    data() {
        return {
            rtcClient: null,
            visible: false,
            innerVisible: false,
            loading: false,
            recordTimeStamp: 0,
            recordTimeInterval: null,
            recording: false,
            recordComplete: false,
            tipMsgShow: false,
            tipMsg: '未检测到摄像和录音设备',
            roomJoined: false,
        }
    },
    props: {
        uuid: String,
        roomId: Number
    },
    computed: {
        unionId() {
            return this.$store.state.user.userInfo.unionId;
        },
        recordTimes() {
            if(this.recordTimeStamp == 0) {
                return '';
            }
            let timeStamp = this.recordTimeStamp;
            let seconds = Math.floor(timeStamp / 1000);
            let minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
            if(minutes < 10) {
                minutes = '0' + minutes;
            }
            if(seconds < 10) {
                seconds = '0' + seconds;
            }
            return minutes + ':' + seconds;
        }
    },
    mounted() {
    },
    methods: {
        show() {
            this.visible = true;
            this.loading = true;
            mediaService.getUserSig()
                .then(res =>{
                    console.log(this.uuid);
                    this.loading = false;

                    const sdkAppId = getAppId();
                    const userSig = res;

                    this.rtcClient = new RtcClient({
                        sdkAppId: sdkAppId,
                        userSig: userSig,
                        userId: this.unionId,
                        userDefineRecordId: this.uuid,
                        roomId: this.roomId
                    });

                    this.rtcClient.join(1)
                        .then(() =>{
                            this.roomJoined = true;
                        }).catch(err => {
                            if(err.message.indexOf('Requested device not found') > -1 || err.name == 'NotFoundError') {
                                if(/macintosh|mac os x/i.test(navigator.userAgent)) {
                                    this.tipMsg = '应用未被授予摄像头/麦克风的访问权限，请在系统偏好设置中授权访问权限';
                                }else {
                                    this.tipMsg = '请先打开摄像头/麦克风';
                                }
                                this.tipMsgShow = true;
                            }else {
                                this.tipMsg = err.message || '录制遇到了问题';
                                this.tipMsgShow = true;
                            }
                        });
                }).catch(err =>{
                    this.loading = false;
                    shortTips(err.msg || '获取签名失败');
                    this.visible = false;
                })
        },
        hideDialog(donotCheck) {
            if(!donotCheck && (this.recording || this.recordComplete)) {
                this.innerVisible = true;
                return false;
            }

            this.roomJoined = false;
            this.visible = false;
            this.stopRecord();
            this.rtcClient && this.rtcClient.reset();
            this.recordComplete = false;
        },
        startRecord() {
            this.recording = true;
            this.$emit('start-record');
            this.recordTimeInterval = setInterval(() => {
                this.recordTimeStamp += 1000;
            }, 1000);
            if(this.rtcClient) {
                this.rtcClient.publish()
                    .then()
                    .catch(err =>{
                        this.tipMsg = err.message || '录制遇到了问题';
                        this.tipMsgShow = true;
                    }) 
            }
        },
        stopRecord() {
            if(this.recording && this.recordTimeStamp < 10000) {
                shortTips('录制时长需在10秒以上，请稍后重试');
                return false;
            }
            this.recording = false;
            this.recordComplete = true;
            clearInterval(this.recordTimeInterval);
            this.recordTimeInterval = null;
            this.recordTimeStamp = 0;
            if(this.rtcClient && this.rtcClient.isPublished_) {
                this.rtcClient.unpublish();
            }
        },
        uploadRecord() {
            this.$emit('upload', {type: 1, recordKey: this.uuid});
            this.hideDialog(true);
        },
        handleCancelInner() {
            this.innerVisible = false;
        },
        handleConfirmInner() {
            this.innerVisible = false;
            this.hideDialog(true);
        },
    }
}
</script>

<style lang="scss" scoped>
    .video-box {
        position: relative;
        width: 1000px;
        height: 650px;
        
        .before-video-load {
            padding-top: 300px;
            font-size: 16px;
            text-align: center;
        }

        .tip-box {
            width: 100%;
            height: 100%;
            background-color: #ddd;

            p {
                padding-top: 300px;
                text-align: center;
                font-size: 16px;
            }
        }

        .close {
            width: 18px;
            height: 18px;
            color: #fff;
            border-radius: 9px;
            border: 1px solid #fff;
            position: absolute;
            right: 14px;
            top: 14px;
            line-height: 18px;
            cursor: pointer;
            z-index: 10;

            &.close-white {
                color: #e95352;
                border-color: #e95352;
            }

            &::before {
                content: "×";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .close-tip {
                width: 224px;
                color: #fff;
                display: none;
                position: absolute;
                left: 40px;
                top: -8px;
            }
            &:hover {
                color: #fff;
                background-color: #e95352;
                border-color: #e95352;
                .close-tip {
                    display: inline-block;
                }
            }
        }

        .operate-btns {
            position: absolute;
            left: 470px;
            bottom: 22px;
            z-index: 9;

            span {
                vertical-align: top;
                cursor: pointer;
            }

            .start-record {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 60px;
                margin-right: 12px;
                background: #FFFFFF;
                box-shadow: 0 0 8px 0 #DDDDDD;
                border-radius: 50%;

                &::after {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    width: 44px;
                    height: 44px;
                    background: rgba(56,188,157,0.80);
                    border-radius: 50%;
                }
            }
            .stop-record {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 60px;
                margin-right: 12px;
                background: #FFFFFF;
                box-shadow: 0 0 8px 0 #DDDDDD;
                border-radius: 50%;

                &::after {
                    content: '';
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    width: 20px;
                    height: 20px;
                    background: rgba(56,188,157,0.80);
                    border-radius: 2px;
                }
            }
            .upload-record {
                float: right;
                margin-right: 30px;
                margin-top: 10px;
            }
        }

        .time-box {
            position: absolute;
            top: 14px;
            left: 0;
            right: 0;
            height: 25px;
            line-height: 25px;
            text-align: center;
            font-size: 18px;
            color: #fff;
            z-index: 9;

            span {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 12px;
                border-radius: 50%;
                background-color: rgba(56,188,157,0.80);
            }
        }
    }
    .video-record-dialog /deep/ {
        .el-dialog__header {
            display: none;
        }
        .el-dialog .el-dialog__body {
            padding: 0;
        }

    }
</style>>
