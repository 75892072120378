<template>
    <div class="media-record-entry">
        <video-record-dialog ref="videoRecordDialog" 
            @upload="handleUploadMedia" 
            :job-ids="jobIds" 
            :uuid="recordUuid"
            :room-id="roomId"
            @start-record="handleStartRecord"
        ></video-record-dialog>
        <audio-record-dialog 
            ref="audioRecordDialog" 
            @upload="handleUploadMedia" 
            :job-ids="jobIds" 
            :uuid="recordUuid"
            :room-id="roomId"
            @start-record="handleStartRecord"
        ></audio-record-dialog>
        <media-upload-dialog ref="mediaUploadDialog" @upload-dialog-close="handleUploadDialogClose"></media-upload-dialog>
        <browser-not-support-dialog ref="browserNotSupportDialog"></browser-not-support-dialog>
        <add-record-desc-dialog ref="addRecordDescDialog" @upload-success="handleUploadSuccess"></add-record-desc-dialog>
    </div>
</template>

<script>
import VideoRecordDialog from '#/component/mediaRecord/videoRecordDialog.vue';
import MediaUploadDialog from '#/component/mediaRecord/mediaUploadDialog.vue';
import BrowserNotSupportDialog from '#/component/mediaRecord/browserNotSupportDialog.vue';
import AudioRecordDialog from '#/component/mediaRecord/audioRecordDialog.vue';
import AddRecordDescDialog from '#/component/mediaRecord/addRecordDescDialog.vue';

import TRTC from 'trtc-js-sdk';

export default {
    name: 'mediaRecordEntry',
    components: {
        VideoRecordDialog,
        MediaUploadDialog,
        BrowserNotSupportDialog,
        AudioRecordDialog,
        AddRecordDescDialog
    },
    data() {
        return {
            recordUuid: '',
            roomId: 0,
            jobIds: [],
            startRecordTime: ''
        }
    },
    mounted() {
    },
    methods: {
        show(command, ids) {
            this.jobIds = ids;
            TRTC.checkSystemRequirements().then((result) => {
                if(!result) {
                    this.$refs.browserNotSupportDialog.show();
                }else {
                    this.recordUuid = _generateUUID();
                    this.roomId = parseInt(Math.random() * 100000);
                    if(command == 'video') {
                        this.$refs.videoRecordDialog.show();
                        console.log('123');
                    }
                    if(command == 'audio') {
                        this.$refs.audioRecordDialog.show();
                        console.log('456');
                    }
                }
            });
        },
        handleUploadMedia(json) {
            this.$refs.addRecordDescDialog.show({
                type: json.type,
                recordKey: json.recordKey,
                jobIds: this.jobIds,
                roomId: this.roomId,
                recordStartTime: this.startRecordTime
            });
        },
        handleUploadSuccess(type) {
            this.$refs.mediaUploadDialog.show(type);
        },
        handleUploadDialogClose() {
            this.$emit('record-media-success');
        },
        handleStartRecord() {
            this.startRecordTime = + new Date();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>>
