var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "audio-record-dialog",
      attrs: {
        title: "职播-录音",
        visible: _vm.visible,
        width: "480px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.beforeHideDialog,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.tipMsgShow
        ? _c("div", { staticClass: "tip-msg" }, [_vm._v(_vm._s(_vm.tipMsg))])
        : _vm._e(),
      !_vm.tipMsgShow
        ? _c(
            "div",
            {
              staticClass: "audio-box col-div",
              staticStyle: { "justify-content": "flex-end" },
            },
            [
              !_vm.roomJoined
                ? _c("p", { staticClass: "before-record" }, [
                    _vm._v("录音设备启动中..."),
                  ])
                : _vm._e(),
              !_vm.recording && !_vm.recordComplete && _vm.roomJoined
                ? _c("div", { staticClass: "start-record" }, [
                    _c("p", [_vm._v("点击录音")]),
                    _c("span", { on: { click: _vm.startRecord } }),
                  ])
                : _vm._e(),
              _vm.recording
                ? _c("div", { staticClass: "recording" }, [
                    _c("p", [
                      _c("span", { staticClass: "left" }, [
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                      ]),
                      _c("span", { staticClass: "recording-time" }, [
                        _vm._v(_vm._s(_vm.recordTimes)),
                      ]),
                      _c("span", { staticClass: "right" }, [
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                        _c("i"),
                      ]),
                    ]),
                    _c("span", { on: { click: _vm.stopRecord } }),
                  ])
                : _vm._e(),
              _vm.recordComplete
                ? _c(
                    "div",
                    { staticClass: "upload-btns" },
                    [
                      _c("p", [_vm._v("录制完成！")]),
                      _c("el-button", { on: { click: _vm.cancelUpload } }, [
                        _vm._v("取消"),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.uploadRecord },
                        },
                        [_vm._v("上 传")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: "提示",
            visible: _vm.innerVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c("p", [_vm._v("你录制的职播还没有上传，确定关闭？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelInner } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmInner },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }