<template>
    <el-dialog
        class="media-upload-dialog"
        :title="title"
        :visible.sync="visible"
        width="568px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="hideDialog"
    >
        <div class="upload-percentage upload-success">
            <el-progress type="circle" :percentage="100" status="success"></el-progress>
            <p>上传成功</p>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'mediaUploadDialog',
    data() {
        return {
            title: '',
            visible: false,
        }
    },
    mounted() {
    },
    methods: {
        show(type) {
            if(type == 0) {
                this.title = '上传语音';
            }else {
                this.title = '上传视频';
            }
            this.visible = true;
        },
        hideDialog() {
            this.visible = false;
            this.$emit('upload-dialog-close');
        }
    }
}
</script>

<style lang="scss" scoped>
    .upload-percentage {
        height: 240px;
        text-align: center;

        .uploading-text {
            font-size: 14px;
            color: #4A4A4A;
            width: 100%;
            text-align: left;
            margin-bottom: 33px;
        }

        .el-button {
            display: block;
            margin: 30px auto 0;
        }

        /deep/ .el-progress__text .el-icon-check {
            font-size: 30px;
            color: #52C41A;
        }
    }
    .upload-success {
        padding-top: 32px;
        
        p {
            font-size: 22px;
            color: #4A4A4A;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
</style>>
