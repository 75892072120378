var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "media-record-entry" },
    [
      _c("video-record-dialog", {
        ref: "videoRecordDialog",
        attrs: {
          "job-ids": _vm.jobIds,
          uuid: _vm.recordUuid,
          "room-id": _vm.roomId,
        },
        on: {
          upload: _vm.handleUploadMedia,
          "start-record": _vm.handleStartRecord,
        },
      }),
      _c("audio-record-dialog", {
        ref: "audioRecordDialog",
        attrs: {
          "job-ids": _vm.jobIds,
          uuid: _vm.recordUuid,
          "room-id": _vm.roomId,
        },
        on: {
          upload: _vm.handleUploadMedia,
          "start-record": _vm.handleStartRecord,
        },
      }),
      _c("media-upload-dialog", {
        ref: "mediaUploadDialog",
        on: { "upload-dialog-close": _vm.handleUploadDialogClose },
      }),
      _c("browser-not-support-dialog", { ref: "browserNotSupportDialog" }),
      _c("add-record-desc-dialog", {
        ref: "addRecordDescDialog",
        on: { "upload-success": _vm.handleUploadSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }